import * as React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'

import Bio from "@components/bio"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Link from "@components/link"
import GithubComments from "@components/githubComments"
import Tag from "@components/tag"
import TagWidget from "@components/tagWidget"
import { DateSpan } from "@components/dateSpan"
import ImgCache from "@utils/ImgCache"

interface Props {
  readonly data: BlogPostQueryData
}

const BlogPostTemplate: React.FunctionComponent<Props> = ({ data }) => {
  const post = data.markdownRemark
  const fm = post.frontmatter
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout title={siteTitle}>
      <SEO
        title={fm.title}
        description={fm.description || post.excerpt}
        image={fm.image}
        meta={[{
          name: 'keywords', content:[...fm.tags].toString()
        }]}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        
        <div key={fm.title} id="main">
          <div className="container-fluid">
            <div className="row d-flex">
              <div className="col-lg-8 px-md-5 py-5">
                <GlassPost className="blog-entry float-none row m-0">
                  {fm.image ? <PostImg
                    className="mx-auto img-fluid lazyload"
                    data-src={ ImgCache(fm.image) }
                    alt={fm.title}
                    src="/media/image-placeholder.webp"
                  ></PostImg> : null}

                  <div className="desc w-100">
                    <h1 className="mb-3">{fm.title}</h1>
                    <DateSpan>{ fm.date }</DateSpan>

                    <div className="inner-html" dangerouslySetInnerHTML={{ __html: post.html }}></div>

                    {fm.tags && fm.tags.length ? (
                      <div className="tag-widget post-tag-container mb-3 mt-3">
                        <div className="tagcloud">
                          {fm.tags.map((tag: string) => <Tag key={tag} tag={tag} />)}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="w-100">
                    <GithubComments />
                  </div>
                </GlassPost>
              </div>
              <div className="col-xl-4 px-md-5 py-5">
                {/* <div className="sidebar-box">
                  <SearchWidget
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  ></SearchWidget>
                </div> */}
                <div className="sidebar-box">
                    <TagWidget></TagWidget>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          {/* <Bio /> */}
        </footer>
      </article>
      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

const GlassPost = styled.div`
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 20px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #fff;
`;

const PostImg = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: cover;
`;

export default BlogPostTemplate

interface BlogPostQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  markdownRemark: {
    id?: string
    excerpt?: string
    html: string
    frontmatter: {
      title: string
      image?: string
      date: string
      description: string
      tags: string[]
    }
  }
  previous: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
    }
  }
  next: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
    }
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        tags
        title
        image
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
