import React, { FunctionComponent, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useScript from '@utils/UseScript'

interface GithubCommentsQueryData {
  site: {
    siteMetadata: {
      githubComments: {
        url: string,
        repo: string,
        issueTerm: string,
        label: string,
        theme: string,
        crossOrigin: string,
      }
    }
  }
}

const GithubComments: FunctionComponent = () => {

  const data: GithubCommentsQueryData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          githubComments {
            url
            repo
            issueTerm
            label
            theme
            crossOrigin
          }
        }
      }
    }
  `)

  const wrapperId = 'gh-comments'

  useEffect(() => {
    const ghCommentSettings = data.site.siteMetadata?.githubComments;
    if (!ghCommentSettings) return;

    const useScriptCallback = useScript(
      ghCommentSettings.url,
      `#${wrapperId}`,
      {
        'repo': ghCommentSettings.repo,
        'issue-term': ghCommentSettings.issueTerm,
        'label': ghCommentSettings.label,
        'theme': ghCommentSettings.theme,
        'crossOrigin': ghCommentSettings.crossOrigin,
    })

    return () => {
      if(useScriptCallback) useScriptCallback()
    }
  }, [data]);

  return (
    <div id={wrapperId}></div>
  )
}

export default GithubComments
